import { Options as HotkeyOptions } from 'react-hotkeys-hook'

export enum HotkeyFeature {
  PINCH_TO_ZOOM = 'pinchToZoom',
  UNDO_REDO = 'undoRedo',
  EDITOR_MODE = 'editorMode',
  EDITOR_SHORTCUTS = 'editorShortcuts',
}

export const featureHotkeysMap = {
  pinchToZoom: {
    zoomIn: ['mod + ='],
    zoomOut: ['mod + -'],
    zoomToFit: ['shift + 1'],
    zoomTo100: ['mod + 0'],
    pointerZoomIn: ['z'],
    pointerZoomOut: ['alt + z'],
    grab: ['space'],
  },

  undoRedo: {
    undo: ['mod + z'],
    redo: ['ctrl + y', 'mod + shift + z'],
  },

  editorMode: {
    comment: ['c'],
    edit: ['v'],
    escape: ['esc'],
    pan: ['h'],
  },

  editorShortcuts: {
    delete: ['backspace', 'delete'],
    copy: ['mod + c'],
    paste: ['mod + v'],
    regenerate: ['mod + k'],
    moveToParent: ['\\', 'shift + enter'],
    moveToFirstChild: ['enter'],
    moveToNextSibling: ['tab'],
    moveToPreviousSibling: ['shift + tab'],
    shiftElementToPrevious: ['up', 'left'],
    shiftElementToNext: ['down', 'right'],
  },
} satisfies Record<HotkeyFeature, Record<string, string[]>>

export const hotkeyOptionsDescriptionInjector = (options: HotkeyOptions) => {
  return (
    description: string,
    overrideOptions?: HotkeyOptions,
  ): HotkeyOptions => ({ ...options, ...overrideOptions, description })
}

export const getInitialHotkeyScopes = (): HotkeyFeature[] => {
  return Object.keys(featureHotkeysMap) as HotkeyFeature[]
}
