import { useFetchOrgList } from '@/api/org/useFetchOrgList'
import authStore from '@/app/store/authStore'
import { namespaceStore } from '@/app/store/namespaceStore'
import { default as userInfoStore } from '@/app/store/userInfoStore'
import {
  getCurrentOrgSlug,
  useExtractCurrentOrg,
} from '@/app/store/utils/orgUtils'
import { useFeatureFlags } from '@/contexts/FeatureFlagContext'
import { NamespaceType } from '@/features/Org/types/namespace'
import clsx from '@/lib/utils/clsx'
import { observer } from 'mobx-react-lite'
import { useEffect } from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom'
import { DesktopRecommendationPrompt } from '../../../components/DesktopRecommendationPrompt'
import TetrisLoader from '../../../components/TetrisLoader'

export const ProtectedRoute = observer(() => {
  const location = useLocation()
  const featureFlags = useFeatureFlags()
  const isDesktopRecommendationPromptEnabled =
    featureFlags.DESKTOP_RECOMMENDATION_PROMPT
  const orgSlug = getCurrentOrgSlug()
  const activeNamespace = namespaceStore.activeNamespace
  const {
    data: userOrgs,
    isLoading: isOrgListLoading,
    isError: isOrgListError,
  } = useFetchOrgList()
  // TODO: Move this to OrgRoute
  const currentOrg = useExtractCurrentOrg()

  useEffect(() => {
    authStore.fetchAccessToken().then(() => {
      userInfoStore.fetchUserInfo()
    })
  }, [])

  useEffect(() => {
    if (!activeNamespace && userOrgs && userOrgs.length > 0) {
      namespaceStore.setActiveOrg(userOrgs[0]!.slug)
    }
  }, [activeNamespace, userOrgs])

  // effect to set a default active org if the currently selected org slug is not valid
  useEffect(() => {
    if (!activeNamespace) return

    const isOrgSlugInvalid =
      activeNamespace.type === NamespaceType.ORG &&
      !!userOrgs &&
      !userOrgs.some((org) => org.slug === orgSlug)

    if (isOrgSlugInvalid) {
      namespaceStore.setActiveOrg(userOrgs[0]!.slug)
    }
  }, [activeNamespace, orgSlug, userOrgs])

  const apiCallStatus = [
    authStore.accessToken.state.apiCallStatus,
    userInfoStore.userInfo.state.apiCallStatus,
  ]

  if (
    userInfoStore.userInfo.state.apiCallStatus === 'error' ||
    isOrgListError
  ) {
    return <Navigate to="/login" state={{ from: location }} replace />
  }

  if (
    apiCallStatus.some((status) => ['initial', 'loading'].includes(status)) ||
    isOrgListLoading ||
    !currentOrg ||
    !userOrgs
  ) {
    return (
      <div className="flex h-full w-full items-center justify-center">
        <TetrisLoader />
      </div>
    )
  }

  return (
    <>
      {isDesktopRecommendationPromptEnabled && (
        <div className="lg:hidden">
          <DesktopRecommendationPrompt />
        </div>
      )}
      <div
        className={clsx(
          {
            'hidden lg:block': isDesktopRecommendationPromptEnabled,
          },
          {
            block: !isDesktopRecommendationPromptEnabled,
          },
        )}
      >
        <Outlet />
      </div>
    </>
  )
})
