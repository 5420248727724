import * as coda from '@thesysdev/coda-client'
import axios from 'axios'
import { ServerSentEventClient } from '@notes/sse-client'
export const axiosInstance = axios.create({
  withCredentials: true,
  headers: {
    'X-Thesys-Cache-Version': __CACHE_VERSION__,
  },
})

export const api = coda.DefaultApiFactory(
  {
    isJsonMime: () => true,
  },
  undefined,
  axiosInstance,
)

export const sseClient = new ServerSentEventClient({
  withCredentials: true,
})

export const streamApi = coda.StreamApi(sseClient)
