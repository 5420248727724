import { bgColorForString } from '@/lib/utils/user'
import { datadogRum } from '@datadog/browser-rum'
import { ApiLinkedStore, api } from 'instrument'
import { action, makeObservable, runInAction } from 'mobx'
import posthog from 'posthog-js'
import invariant from 'tiny-invariant'

const MAX_PROFILE_PICTURE_SIZE = 2 * 1024 * 1024
export class ProfilePictureSizeError extends Error {}

class UserInfoStore {
  userInfo = new ApiLinkedStore(() => api.userControllerMe())
  constructor() {
    makeObservable(this, {
      fetchUserInfo: action,
    })
  }

  fetchUserInfo() {
    return this.userInfo.fetch().then(() => {
      if (this.userInfo.state.apiCallStatus === 'success') {
        posthog.identify(this.userInfo.state.data.id, {
          email: this.userInfo.state.data.email,
        })
        datadogRum.setUser({
          id: this.userInfo.state.data.id,
          email: this.userInfo.state.data.email,
        })
        datadogRum.startSessionReplayRecording()

        const phSessionId = posthog.get_session_id()
        const ddSessionId = datadogRum.getInternalContext()?.session_id
        posthog.register_for_session({
          dd_session_id: ddSessionId,
        })
        datadogRum.setGlobalContextProperty('ph_session_id', phSessionId)
      }
    })
  }

  updateUserProfile = async (data: { firstName: string; handle: string }) => {
    const resp = await api.userControllerUpdate({
      id: this.userId,
      params: data,
    })
    runInAction(() => {
      this.userInfo.state = { apiCallStatus: 'success', data: resp.data }
    })
    return resp.data
  }

  async uploadProfilePicture(file: File) {
    // check file size
    if (file.size > MAX_PROFILE_PICTURE_SIZE) {
      throw new ProfilePictureSizeError(
        'Profile picture must be smaller than 2MB',
      )
    }

    const artifactUpload =
      await api.userControllerGeneratePresignedURLForImageUpload()
    const { presignedUrl, fields } = artifactUpload.data

    const formData = new FormData()
    fields.forEach((field) => {
      formData.append(field.key, field.value)
    })
    formData.append('Content-Type', file.type)
    formData.append('file', file)

    const awsResp = await fetch(presignedUrl, {
      method: 'POST',
      body: formData,
    })
    if (awsResp.status !== 204) {
      return false
    }
    // update profile picture status
    const finalResp = await api.userControllerFinalizeImageUpload()
    if (finalResp.status !== 204) {
      return false
    }
    return true
  }

  get userId() {
    invariant(this.userInfo.state.apiCallStatus === 'success')
    return this.userInfo.state.data.id
  }

  get email() {
    invariant(this.userInfo.state.apiCallStatus === 'success')
    return this.userInfo.state.data.email
  }

  get name() {
    invariant(this.userInfo.state.apiCallStatus === 'success')
    return this.userInfo.state.data.firstName ?? ''
  }

  get handle() {
    invariant(this.userInfo.state.apiCallStatus === 'success')
    invariant(this.userInfo.state.data.handle)
    return this.userInfo.state.data.handle
  }

  get defaultBgColor() {
    invariant(this.userInfo.state.apiCallStatus === 'success')
    return bgColorForString(this.userInfo.state.data.id)
  }

  get permissions() {
    invariant(this.userInfo.state.apiCallStatus === 'success')
    return this.userInfo.state.data.permissions
  }

  get maybePermissions() {
    return this.userInfo.state.apiCallStatus === 'success'
      ? this.userInfo.state.data.permissions
      : undefined
  }

  getOrgRoleByOrgId(orgId: string) {
    invariant(this.userInfo.state.apiCallStatus === 'success')
    return this.userInfo.state.data.orgClaims?.find(
      (claim) => claim.orgId === orgId,
    )?.roleId
  }
}

export default new UserInfoStore()
