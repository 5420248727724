import clsx from '@/lib/utils/clsx'
import * as RAlertDialog from '@radix-ui/react-alert-dialog'
import { observer } from 'mobx-react-lite'

interface AlertDialogProps {
  trigger?: React.ReactNode
  title?: React.ReactNode | string
  content?: React.ReactNode
  open?: boolean
  onOpenChange?: (open: boolean) => void
  className?: string
}

export const AlertDialogAction = RAlertDialog.Action
export const AlertDialogCancel = RAlertDialog.Cancel

const AlertDialog = observer(
  ({
    trigger,
    title,
    content,
    open,
    onOpenChange,
    className,
  }: AlertDialogProps) => {
    return (
      <RAlertDialog.Root onOpenChange={onOpenChange} open={open}>
        {trigger && (
          <RAlertDialog.Trigger asChild>{trigger}</RAlertDialog.Trigger>
        )}
        <RAlertDialog.Portal>
          <RAlertDialog.Overlay className="fixed inset-0 z-50 bg-black/80 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0" />
          <RAlertDialog.Content
            className={clsx(
              'fixed left-[50%] top-[50%] z-50 grid w-[400px] max-w-lg translate-x-[-50%] translate-y-[-50%] gap-4 rounded-lg border bg-white shadow-lg duration-200 data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[state=closed]:slide-out-to-left-1/2 data-[state=closed]:slide-out-to-top-[48%] data-[state=open]:slide-in-from-left-1/2 data-[state=open]:slide-in-from-top-[48%]',
              className,
            )}
          >
            <RAlertDialog.Title className="flex justify-between">
              {typeof title === 'string' ? (
                <span className="text-size-title text-primary">{title}</span>
              ) : (
                title
              )}
            </RAlertDialog.Title>
            {content}
          </RAlertDialog.Content>
          <RAlertDialog.Overlay />
        </RAlertDialog.Portal>
      </RAlertDialog.Root>
    )
  },
)

export default AlertDialog
