import { useFetchOrgBillingInfo } from '@/api/org/useFetchOrgList'
import { useExtractCurrentOrg } from '@/app/store/utils/orgUtils'
import AlertDialog from '@/components/AlertDialog'
import TetrisLoader from '@/components/TetrisLoader'
import { useQuery } from '@tanstack/react-query'
import { api } from 'instrument'
import { observer } from 'mobx-react-lite'
import { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { toast } from 'sonner'

const useStripeReturnStatusCheck = (orgId: string) => {
  const { refetch: refetchBilling } = useFetchOrgBillingInfo({ orgId: orgId })
  const [searchParams, setSearchParams] = useSearchParams()
  const [sessionId] = useState(() => searchParams.get('session_id'))
  const [isProcessing, setIsProcessing] = useState(!!sessionId)

  useEffect(() => {
    if (searchParams.get('session_id')) {
      const params = new URLSearchParams(searchParams)
      params.delete('session_id')
      setSearchParams(params, { replace: true })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  const { data: checkoutSessionData } = useQuery({
    queryKey: ['checkout-session', sessionId, orgId],
    retry: (failureCount) => {
      return failureCount <= 3
    },
    enabled: !!sessionId,
    queryFn: async () => {
      const res = await api.billingControllerGetStripeCheckoutSession({
        sessionId: sessionId!,
        orgId,
      })
      if (res.data.status === 'complete' && !res.data.processedByBilling) {
        await new Promise((resolve) => setTimeout(resolve, 1000))
        throw new Error('Payment not yet processed by billing')
      }
      return res.data
    },
  })

  useEffect(() => {
    if (checkoutSessionData) {
      refetchBilling()
      setIsProcessing(false)
      switch (checkoutSessionData.status) {
        case 'complete': {
          toast.success('Payment successful. Your subscription is active now.')
          break
        }
        case 'expired':
        case 'open':
        default:
          toast.error('Payment could not be processed. Please try again.')
          break
      }
    }
  }, [checkoutSessionData, refetchBilling])

  return { isProcessing }
}

export const VerifyCheckoutStatus = observer(() => {
  const currentOrg = useExtractCurrentOrg()
  const { isProcessing } = useStripeReturnStatusCheck(currentOrg?.id ?? '')

  return isProcessing ? (
    <AlertDialog
      open={true}
      title={
        <div className="px-6 pt-6 text-xl font-semibold">
          Processing Payment
        </div>
      }
      content={
        <div className="flex flex-col items-center gap-6 px-6 pb-6">
          <div className="py-16">
            <TetrisLoader hideText />
          </div>
          <p className="text-center text-gray-600">
            Please wait while we process your payment. Do not close or refresh
            this window.
          </p>
        </div>
      }
    />
  ) : null
})
