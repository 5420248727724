import authStore from '@/app/store/authStore'
import { redirectToLogin } from '@/lib/thesys/utils/common'
import { isAxiosError } from 'axios'
import { axiosInstance, sseClient } from 'instrument'
import { toast } from 'sonner'

export const setupAxios = (serverurl: string) => {
  axiosInstance.defaults.baseURL = serverurl
  sseClient.setBasePath(serverurl)

  // Add an interceptor to handle 401 and 429 responses and reload the page
  axiosInstance.interceptors.response.use(
    (response) => response,
    async (error) => {
      const originalRequest = error.config

      if (isAxiosError(error)) {
        if (error.response?.status === 401) {
          if (error.config?.url === '/auth/session/refresh') {
            redirectToLogin()
            return
          }
          if (!originalRequest._retry) {
            return await refreshAccessTokenAndRetry(originalRequest, error)
          } else {
            redirectToLogin()
          }
        } else if (error.response?.status === 429) {
          toast.error(error.response?.data?.message ?? 'Too many requests')
        }
      }

      return Promise.reject(error)
    },
  )
}

async function refreshAccessTokenAndRetry(originalRequest: any, error: any) {
  originalRequest._retry = true

  try {
    await authStore.refetchAccessToken()
    return axiosInstance(originalRequest)
  } catch (refreshError) {
    redirectToLogin()
    return Promise.reject(error)
  }
}
