import clsx from '@/lib/utils/clsx'
import _ from 'lodash'
import classes from './TetrisLoader.module.css'

interface TetrisLoaderProps {
  tetrisClassName?: string
  hideText?: boolean
}

const TetrisLoader = ({ tetrisClassName, hideText }: TetrisLoaderProps) => {
  const loadingStrings = [
    'Forging the fantastic',
    'Assembling the Awesome',
    'Building the Brilliant',
  ]
  return (
    <div className="flex items-center gap-2 text-black">
      <div
        className={clsx(
          classes['loader'],
          'h-[16px] w-[24px]',
          tetrisClassName,
        )}
      ></div>
      {!hideText && <div>{_.sample(loadingStrings)}</div>}
    </div>
  )
}

export default TetrisLoader
