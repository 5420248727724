import { VerifyCheckoutStatus } from '@/app/routes/OrgRoutes/VerifyCheckoutStatus'
import { useExtractCurrentOrg } from '@/app/store/utils/orgUtils'
import { OrgEmptyState } from '@/features/Org/OrgEmptyState'
import { Outlet } from 'react-router-dom'

export const OrgRoutes = () => {
  const currentOrg = useExtractCurrentOrg()

  if (!currentOrg) {
    return <OrgEmptyState />
  }
  return (
    <>
      <VerifyCheckoutStatus />
      <Outlet />
    </>
  )
}
